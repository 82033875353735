<template>
  <div class="hello">
    <h1>{{ randomGua }}</h1>
  </div>
</template>

<script>
export default {
  data() {  
    return {  
      guaArray: [ 
      "乾䷀",  "坤䷁", "屯䷂", "蒙䷃", "需䷄", "讼䷅", "师䷆", "比䷇",
      "小畜䷈", "履䷉", "泰䷊", "否䷋", "同人䷌", "大有䷍", "谦䷎", "豫䷏",
      "随䷐", "蛊䷑", "临䷒", "观䷓", "噬嗑䷔", "贲䷕", "剥䷖", "复䷗",
      "无妄䷘", "大畜䷙", "颐䷚", "大过䷛", "坎䷜", "离䷝", "咸䷞", "恒䷟",
      "遁䷠", "大壮䷡", "晋䷢", "明夷䷣", "家人䷤", "睽䷥", "蹇䷦", "解䷧",
      "损䷨", "益䷩", "夬䷪", "姤䷫", "萃䷬", "升䷭", "困䷮", "井䷯",
      "革䷰", "鼎䷱", "震䷲", "艮䷳", "渐䷴", "归妹䷵", "丰䷶", "旅䷷",
      "巽䷸", "兑䷹", "涣䷺", "节䷻", "中孚䷼", "小过䷽", "既济䷾", "未济䷿"
      ],  
      randomGua: ''  
    };  
  },  
  methods: {  
    getRandomGua() {  
      const randomIndex = Math.floor(Math.random() * this.guaArray.length);  
      this.randomGua = this.guaArray[randomIndex];  
    }  
  },  
  created() {  
    this.getRandomGua();  
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
